<div *ngIf="referenceData$ | async as refData">
  <h4 *ngIf="refData?.length > 0">{{"productDetail.alternativeProducts" | cxTranslate}}</h4>
  <div class="custom-flex-wrap">
    <ng-container *ngFor="let data of refData">
      <div class="mr-4 mb-3">
        <div class="card custom-flex-center">
          <div class="row">
            <a routerLink="{{getProductUrl(data?.target?.url)}}" class="col-md-4 custom-align-center">
              <img [src]="getImageUrl(data)" class="img-fluid card-img" alt="Alternate Product Image">
            </a>
            <div class="col-md-8">
              <div class="card-body">
                <a routerLink="{{getProductUrl(data?.target?.url)}}" class="cstm-anchor-primary">
                  {{data?.target?.baseProduct}}
                </a>
                <a routerLink="{{getProductUrl(data?.target?.url)}}" class="cstm-anchor-primary">
                  {{data?.target?.name}}
                </a>
                <h5 class="card-text">
                  {{checkPriceData(data)
                  ? (getBaseOrVariantOptions(data)?.priceData?.value | currencyPrice)+' '+(getBaseOrVariantOptions(data)?.priceData?.currencyIso | currencyFormat)
                  : ('productDetail.notAvailable' | cxTranslate)}}
                </h5>
                <div class="row justify-content-end mt-15">
                  <div class="col-12 col-lg-12">
                    <div *ngIf="showAddToCart(data)"
                      class="quantity-selector d-flex flex-wrap flex-md-nowrap flex-lg-wrap flex-xl-nowrap justify-content-end">
                      <app-number-input-template
                        [code]="getCode(data)"
                        [step]="checkStep(data)"
                        [minimumQty]="checkMinQtyAndValue(data)"
                        [maximumQty]="checkMaxQty(data, data?.target?.code)"
                        [maxQtyLimit]="checkMaxLimit(data?.target?.maxQuantity)"
                        [value]="checkMinQtyAndValue(data)"
                        [isMaxLmtReached]="checkIsMaxLmtReached(data, data?.target?.code)"
                        [buttonName]="(checkButtonName(data))|cxTranslate"
                        class="w-100"
                      ></app-number-input-template>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
</div>