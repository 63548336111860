import { Component } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { ProductDetailsService } from '../../../../SGRE-custom/product-details-page/services/product-details-service';
import { switchMap, map } from 'rxjs';
import { environment } from '../../../../../environments/environment';
import { GlobalService } from '../../../services/global.service';
import { Options, ProductVariant } from '../../../../SGRE-custom/product-details-page/models/product-info';
import { Reference } from '../../../../SGRE-custom/product-details-page/models/product-references';

@Component({
  selector: 'app-pdp-related-product',
  templateUrl: './pdp-related-product.component.html',
  styleUrl: './pdp-related-product.component.scss'
})
export class PdpRelatedProductComponent {

  referenceData$: Observable<Reference[]>;
  emptyImagePath: string = '../../../../assets/images_and_icons/image1.jpg';

  constructor(
    private productsService: ProductDetailsService,
    private globalService: GlobalService
  ) { }

  ngOnInit(): void {
    this.referenceData$ = this.productsService.pCode$
      .pipe(
        switchMap((pCodeVal: string) => (pCodeVal?.length > 0) ? this.productsService.getReferences(pCodeVal) : ''),
        map((data) => data?.references?.filter((obj) => obj?.referenceType === 'OTHERS'))
      );
  }

  getProductUrl(urlVal: string): string {
    return this.globalService.getProductUrl(urlVal);
  }

  getImageUrl(data: any): string | null {
    const imageUrl = data?.target?.images?.find((val) => val.imageType === 'PRIMARY')?.url;
    return imageUrl
      ? environment.siteUrls.getBackofficeUrl + imageUrl.split('/medias/')?.at(1)
      : this.emptyImagePath;
  }

  getBaseOrVariantOptions(data: Reference): Options | null {
    return (data?.target?.baseOptions?.length > 0)
      ? data.target.baseOptions.at(0)?.options?.find((val) => val.code.endsWith(ProductVariant.NEW))
      : data.target.variantOptions?.find((val) => val.code.endsWith(ProductVariant.NEW));
  }
}